import { Component, Injector, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Debug from 'debug';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { CouponDTO, CouponFilterDTO } from '@modeso/types__twint-lib-coupons';
import { Output, EventEmitter } from '@angular/core';
import { TwintCouponsProvider } from '../../providers/twint.coupons.provider';
import { ConfirmFeatureDialog } from '../../dialog/confirmFeatureDialog/confirmFeatureDialog';
import { FormControl, FormGroup } from '@angular/forms';

const debug = Debug('modeso:twint-admin:campaigns');

@Component({
  selector: 'app-page-manage-coupons',
  templateUrl: './manage-coupons.page.html',
  styleUrls: ['./manage-coupons.page.scss'],
})
export class ManageCouponsPage implements OnDestroy {
  dataSource = new MatTableDataSource<CouponDTO>();
  coupons$: Observable<any>;
  couponsFeature: boolean;
  couponsFeature$: Observable<boolean>;
  initializationState$: Observable<boolean>;
  initializationState: boolean = false;
  navLinks;
  activeLink;
  archiveState;
  searchErrors: boolean = false;
  searchForm: FormGroup;
  private couponFilterData: CouponFilterDTO = {};
  @Input() hasReadPermission = false;
  @Input() hasWritePermission = false;
  @Output() addCouponClick = new EventEmitter<string>();
  protected subscriptions = [];

  constructor(injector: Injector, private couponProvider: TwintCouponsProvider, public dialog: MatDialog) {
    // dispatch fetching coupons action
    this.checkCouponsFeature();

    // get coupons from store as observable and map it to set paginator
    this.coupons$ = this.couponProvider.getCoupons$();
    this.couponsFeature$ = this.getCouponsFeature();
    this.initializationState$ = this.couponProvider.getCouponsFeatureInitializationState$();
    this.subscriptions.push(
      this.couponsFeature$.subscribe((state) => {
        this.couponsFeature = state;
      })
    );
    this.searchForm = new FormGroup({
      couponName: new FormControl(),
      couponCode: new FormControl()
    } )
    this.subscriptions.push(
      this.initializationState$.subscribe((state) => {
        setTimeout(() => {
          this.initializationState = state;
        }, 250);
      })
    );
  }

  ngOnInit() {
      this.populateNavLinks();
      this.fetchCoupons();
  }


  addCoupon() {
    this.addCouponClick.emit('OpenAddCoupon');
  }

  toggleCouponsFeature() {
    const dialogRef = this.dialog.open(ConfirmFeatureDialog, {
      data: {
        activate: !this.couponsFeature,
      },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.couponProvider.toggleCouponsFeature(!this.couponsFeature);
        }
      })
    );
  }

  checkCouponsFeature() {
    return this.couponProvider.checkCouponsFeature();
  }
  getCouponsFeature() {
    return this.couponProvider.getCouponsFeature$();
  }

  private fetchCoupons(data?: CouponFilterDTO): void {
    
    if(data != null){
      this.couponFilterData = {
        ...this.couponFilterData, 
        ...data                 
      
      }
    }

    return this.couponProvider.fetchCoupons(this.couponFilterData);
  }

  populateNavLinks(): void {
    this.navLinks = [
      {
          label: 'Coupons',
          link: 'all',
          index: 0,
          archiveState: false

      }
      , {
          label: 'Archived Coupons',
          link: 'archived',
          index: 1,
          archiveState: true
      },
    ];
    this.activeLink = this.navLinks[0].link;
    this.archiveState = this.navLinks[0].archiveState;
  }

  changeNavigation(archiveState): void{
    this.activeLink = this.navLinks.find((link)=> link.archiveState === archiveState).link;
    this.archiveState = archiveState;
  }

  changeSortColumnAndDirection(data: CouponFilterDTO): void{
    this.fetchCoupons(data);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribe) => {
      subscribe.unsubscribe();
    });
  }

  searchCoupons(): void{
    
    if(this.checkIfFormIsValid()){
      // If both are filled, return an error and nullify their values
      if (this.couponName != null && this.couponName.length > 0 && this.couponCode != null && this.couponCode.length > 0) {
        this.searchForm.get('couponName')?.setValue(null);
        this.searchForm.get('couponCode')?.setValue(null);
        this.searchErrors = true;
      } else {
        this.fetchCoupons({
          couponCode: this.couponCode,
          couponName: this.couponName
        })
        this.searchErrors = false;
      }
    }
    
  }

  clearSearchResults(): void{
    this.searchForm.get('couponName')?.setValue(null);
    this.searchForm.get('couponCode')?.setValue(null); 
    this.fetchCoupons({
      couponCode: this.couponCode,
      couponName: this.couponName
    })
    this.searchErrors = false;  
  }

  private checkIfFormIsValid() : boolean{
    const couponNameValue = this.couponName?.trim();
    const couponCodeValue = this.couponCode?.trim();
    // Enable the submit button if at least one field has a value
    return couponNameValue?.length > 0 || couponCodeValue?.length > 0;
  }

  private get couponName(): string{
    return this.searchForm.get('couponName')?.value;
  }

  private get couponCode() : string{
    return this.searchForm.get('couponCode')?.value;
  }
}
