import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import * as fromReducer from './reducers/twint.coupons.reducer';
import { TwintCouponsEffects } from './effects/twint.coupons.effects';
import { TwintCouponConfig, TwintCouponConfigService } from './twint.coupons.config';
import { TwintCouponsService } from './services/twint.coupons.service';
import { ListCouponsComponent } from './components/list-coupons/list-coupons.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { AddCouponComponent } from './components/add-coupon/add-coupon.component';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmDeletionDialog } from './dialog/confirmDeletionDialog/confirmDeletion';
import { ConfirmCouponInputValuesDialog } from './dialog/confirmCouponInputValuesDialog/confirmCouponInputValues';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DigitOnlyDirective } from './directives/digit-only';
import { ConfirmFeatureDialog } from './dialog/confirmFeatureDialog/confirmFeatureDialog';
import { CouponErrorDialog } from './dialog/errorDialog/errorDialog';
import { ConfirmationDialog } from './dialog/confirmationDialog/confirmationDialog';
import { ManageCouponsPage } from './pages/manage-coupons/manage-coupons.page';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    ListCouponsComponent,
    AddCouponComponent,
    ConfirmDeletionDialog,
    ConfirmCouponInputValuesDialog,
    ConfirmFeatureDialog,
    CouponErrorDialog,
    DigitOnlyDirective,
    ConfirmationDialog,
    ManageCouponsPage,
  ],
  providers: [TwintCouponsService],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatIconModule,
    StoreModule.forFeature(fromReducer.featureKey, fromReducer.reducer),
    EffectsModule.forFeature([TwintCouponsEffects]),
    MatRadioModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    MatMomentDateModule,
    NgxMatMomentModule,
    MatSelectModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatSortModule
  ],
  exports: [ListCouponsComponent, AddCouponComponent, CouponErrorDialog, ConfirmFeatureDialog, ManageCouponsPage],
  entryComponents: [
    ConfirmDeletionDialog,
    ConfirmCouponInputValuesDialog,
    CouponErrorDialog,
    ConfirmFeatureDialog,
    ConfirmationDialog,
  ],
})
export class TwintCouponModule {
  static forRoot(config: TwintCouponConfig): ModuleWithProviders<TwintCouponModule> {
    return {
      ngModule: TwintCouponModule,
      providers: [
        TwintCouponsService,
        {
          provide: TwintCouponConfigService,
          useValue: config,
        },
      ],
    };
  }
}
